<template>
  <div>
    <NavigationBar
      titel="Abrechnungsart anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <payroll-type-editor
          v-model="payrollType"
          mode="new"
        ></payroll-type-editor>
      </v-form>
    </section>
    <CustomDialog
      v-if="helpers.actionDialog.model"
      @close="toggleActionDialog()"
      title="Abrechnungsart erstellen"
      text="Die Prüfung der Arbechnungsart muss kann einige Minuten dauern. Bitte warte, bis der Vorgang abgeschlossen ist."
      :loading="helpers.actionDialog.loading"
      :btnAction="createItem"
      :errorMessage="helpers.actionDialog.errorMessage"
      btnText="Verarbeitung starten"
      width="s"
    ></CustomDialog>
  </div>
</template>

<script>
import { functions, Timestamp } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import PayrollTypeEditor from "@/components/accounting/PayrollTypeEditor.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";

export default {
  name: "accounting-config-tab-payroll-types-payroll-type-new",
  components: {
    NavigationBar,
    PayrollTypeEditor,
    CustomDialog,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.toggleActionDialog,
        },
      ],
      payrollType: {
        title: "",
        shortTitle: "",
        description: "",
        color: "#F44336",
        sortKey: 0,

        // to calculate eligble time:
        timeRoundingType: "", // "none", "roundUp", "roundDown", "roundToNearest", "roundToNearestHalf", "roundToNearestQuarter", "roundToNearestTenth", "roundToNearestTwentieth", "roundToNearestHalfHour", "roundToNearestQuarterHour", "roundToNearestTenthHour", "roundToNearestTwentiethHour"
        // to calculate wage:
        formula: "", // basic calculations to also use with available placeholders: {hours}, {wage}, {time}
        customVariables: [],
        // to calculate due date of payment:
        paymentOfWagesType: "", // "daily", "weekly", "biweekly", "monthly", "quarterly", "yearly"
        quota: {
          // if applicable, payroll type is only x time units in period specified
          // (e.g. 1x per month, 2x per week, 4x per year):
          isApplicable: false,
          timeUnit: "", // "day", "week", "month", "quarter", "year"
          allowedMax: 0, // Number,
          maxUnit: "", // ""
        },
        // only valid in this date range:
        validDuration: {
          start: { date: null, time: null, timestamp: null },
          end: { date: null, time: null, timestamp: null },
        },
        // needs approval; if yes, who can approve:
        needsApproval: false,
        approvalProcess: [],
        //  Example:
        // {
        //   phase: "Phase 1",
        //   needsApproval: false,
        //   approvalBy: [],
        // },
        // {
        //   phase: "Phase 2",
        //   needsApproval: true,
        //   approvalBy: ["User1", "User2"],
        // },
        isDefault: false,
        status: "active", // "active", "disabled", "archived"^
      },
      helpers: {
        formIsValid: false,
        actionDialog: {
          model: false,
          loading: false,
          errorMessage: "",
        },
      },
    };
  },
  computed: {
    newSortKey() {
      return this.$store.state.accounting.payrollTypes.length + 1;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.payrollType.sortKey = this.newSortKey;
    },
    toggleActionDialog() {
      if (this.helpers.actionDialog.model) {
        this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
      } else {
        this.$refs.form.validate();
        if (this.helpers.formIsValid) {
          this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
        } else {
          alert("Prüfen Deine Eingabe und versuchen es erneut.");
        }
      }
    },
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.helpers.actionDialog.errorMessage = "";
        this.helpers.actionDialog.loading = true;

        const callFunction = functions.httpsCallable(
          "accounting-createPayrollType"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,

          payrollType: {
            title: this.payrollType.title,
            shortTitle: this.payrollType.shortTitle,
            description: this.payrollType.description,
            color: this.payrollType.color,
            sortKey: this.payrollType.sortKey,

            timeRoundingType: this.payrollType.timeRoundingType,
            formula: this.payrollType.formula,
            customVariables: this.payrollType.customVariables,
            paymentOfWagesType: this.payrollType.paymentOfWagesType,
            quota: {
              isApplicable: this.payrollType.quota.isApplicable,
              timeUnit: this.payrollType.quota.timeUnit,
              allowedMax: this.payrollType.quota.allowedMax,
              maxUnit: this.payrollType.quota.maxUnit,
            },

            validDuration: {
              start: {
                timestamp: this.convertToTimestamp(
                  this.payrollType.validDuration.start.date,
                  this.payrollType.validDuration.start.time
                ),
              },
              end: {
                timestamp: this.convertToTimestamp(
                  this.payrollType.validDuration.end.date,
                  this.payrollType.validDuration.end.time
                ),
              },
            },

            needsApproval: this.payrollType.needsApproval,
            approvalProcess: this.payrollType.approvalProcess,
            status: this.payrollType.status,
          },
        })
          .then(() => {
            this.$router.push({
              name: "accounnting-config-payroll-types",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          })
          .catch((error) => {
            this.helpers.actionDialog.errorMessage = error.message;
            this.helpers.actionDialog.loading = false;
            console.error("Error creating payroll type: ", error);
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>

<style></style>
